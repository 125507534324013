import axios from '../../../../packages/isomorphic/src/axios';
import actionConstants from './constants';

export const login = (username, password) => async dispatch => {
  dispatch({ type: actionConstants.LOGIN_REQUEST });
  try {
    const response = await axios.post('/auth/login', { username, password });
    const {
      accessToken,
      refreshToken,
      id,
      first_name,
      last_name,
      theme,
      features,
    } = response.data.data;
    dispatch({
      type: actionConstants.LOGIN_SUCCESS,
      accessToken,
      refreshToken,
      username,
      id,
      first_name,
      last_name,
      theme,
      features,
    });
  } catch (err) {
    dispatch({
      type: actionConstants.LOGIN_ERROR,
      error: err,
    });
  }
};

export const loginLDAP = token => async dispatch => {
  dispatch({ type: actionConstants.LOGIN_REQUEST });
  try {
    const response = await axios.post('/auth/login/oauthhandle', {
      ldap_access_token: token,
    });
    const {
      accessToken,
      refreshToken,
      id,
      username,
      first_name,
      theme,
      features,
    } = response.data.data.data;
    dispatch({
      type: actionConstants.LOGIN_SUCCESS,
      accessToken,
      refreshToken,
      username,
      id,
      first_name,
      // last_name,
      theme,
      features,
    });
  } catch (err) {
    dispatch({
      type: actionConstants.LOGIN_ERROR,
      error: err,
    });
  }
};

export const logout = () => dispatch => {
  dispatch({ type: actionConstants.LOGOUT });
};

export const checkAuthorization = () => async dispatch => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (accessToken) {
    dispatch({
      type: actionConstants.LOGIN_SUCCESS,
      accessToken,
      refreshToken,
    });
    try {
      const url = 'auth/me';
      const response = await axios.get(url);
      const user = response.data.data.user;
      dispatch({
        type: actionConstants.LOGIN_SUCCESS,
        accessToken,
        refreshToken: user.refresh_token,
        username: user.username,
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        theme: user.theme,
        features: user.features,
      });
    } catch (err) {
      dispatch({
        type: actionConstants.LOGIN_ERROR,
        error: err,
      });
    }
  }
};
