import actionConstants from './constants';

const INIT_STATE = {
  user: null,
  loader: false,
  error: null,
  editedUser: false,
  passwordChanged: false,
};

const userSettingsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_USER_SETTINGS:
      return {
        ...state,
        loader: true,
        error: null,
        editedUser: false,
      };
    case actionConstants.FETCH_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        user: action.user,
        loader: false,
      };
    case actionConstants.FETCH_USER_SETTINGS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.EDIT_USER_SETTINGS:
      return {
        ...state,
        loader: true,
        error: null,
        editedUser: false,
      };
    case actionConstants.EDIT_USER_SETTINGS_SUCCESS:
      localStorage.setItem('first_name', action.user.first_name);
      localStorage.setItem('last_name', action.user.last_name);
      localStorage.setItem('username', action.user.username);
      return {
        ...state,
        loader: false,
        user: action.user,
        editedUser: true,
      };
    case actionConstants.EDIT_USER_SETTINGS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.CHANGE_USER_PASSWORD:
      return {
        ...state,
        loader: true,
      };
    case actionConstants.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loader: false,
        passwordChanged: true,
      };
    case actionConstants.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SET_PASSWORD_CHANGED_TO_FALSE: {
      return {
        ...state,
        passwordChanged: false,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default userSettingsReducer;
