import _ from 'lodash';
import actionConstants from './constants';

export const INIT_STATE = {
  earnings: [],
  loader: false,
  error: null,
  pagination: null,
};

const earningsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_TOTAL_EARNINGS_BY_TYPE:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_TOTAL_EARNINGS_BY_TYPE_SUCCESS:
      return {
        ...state,
        loader: false,
        earningsByType: action.totalEarnings,
      };
    case actionConstants.FETCH_TOTAL_EARNINGS_BY_TYPE_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default earningsReducer;
