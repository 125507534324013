import actionConstants from './constants';

export const INIT_STATE = {
  walletLoader: false,
  refundLoader: false,
  error: null,
  periods: {},
  selectedPeriodId: null,
  currentPeriod: {},
  transactions: [],
  transactionsBonus: [],
  payouts: [],
  bonusPayout: [],
  totalPayouts: {},
  selectedTransaction: null,
  transactionsPagination: null,
  transactionsBonusPagination: null,
  payoutsPagination: null,
  bonusPayoutPagination: null,
  addedRefund: false,
  extra: {},

  transactionsPayoutAdjustments: [],
  paginationPayoutadjustments: null,
  loaderPayoutAdjustments: false,
};

const modelsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_MODEL_CURRENT_PERIOD:
      return {
        ...state,
        walletLoader: action.refundLoader ? false : true,
        error: null,
        addedRefund: false,
        refundLoader: action.refundLoader ? true : false,
      };
    case actionConstants.FETCH_MODEL_CURRENT_PERIOD_SUCCESS:
      return {
        ...state,
        walletLoader: false,
        currentPeriod: action.currentPeriod,
        refundLoader: false,
      };
    case actionConstants.FETCH_MODEL_CURRENT_PERIOD_ERROR:
      return {
        ...state,
        walletLoader: false,
        error: action.error,
        refundLoader: false,
      };
    //
    case actionConstants.FETCH_MODEL_TRANSACTIONS:
      return {
        ...state,
        walletLoader: true,
        error: null,
        addedRefund: false,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        walletLoader: false,
        transactions: action.transactions,
        transactionsPagination: action.pagination,
        selectedPeriodId: action.idPeriod,
        extra: action.extra,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_ERROR:
      return {
        ...state,
        walletLoader: false,
        error: action.error,
      };
    //

    case actionConstants.FETCH_MODEL_TRANSACTIONS_FIXED:
      return {
        ...state,
        walletLoader: true,
        error: null,
        addedRefund: false,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_FIXED_SUCCESS:
      return {
        ...state,
        walletLoader: false,
        transactionsFixed: action.transactionsFixed,
        paginationFixed: action.paginationFixed,
        selectedPeriodId: action.idPeriod,
        extra: action.extra,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_FIXED_ERROR:
      return {
        ...state,
        walletLoader: false,
        error: action.error,
      };

    //
    case actionConstants.FETCH_MODEL_TRANSACTIONS_BONUS:
      return {
        ...state,
        bonusTransactionsLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_BONUS_SUCCESS:
      return {
        ...state,
        transactionsBonus: action.transactionsBonus,
        transactionsBonusPagination: action.paginationBonus,
        bonusTransactionsLoader: false,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_BONUS_ERROR:
      return {
        ...state,
        bonusTransactionsLoader: false,
        error: action.error,
      };
    //
    case actionConstants.FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT:
      return {
        ...state,
        loaderPayoutAdjustments: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        transactionsPayoutAdjustments: action.transactionsPayoutAdjustments,
        paginationPayoutadjustments: action.paginationPayoutadjustments,
        loaderPayoutAdjustments: false,
      };
    case actionConstants.FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT_ERROR:
      return {
        ...state,
        loaderPayoutAdjustments: false,
        error: action.error,
      };
    //
    case actionConstants.FETCH_MODEL_TIERED_PAYOUT:
      return {
        ...state,
        walletLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_TIERED_PAYOUT_SUCCESS:
      return {
        ...state,
        walletLoader: false,
        payouts: action.payouts,
        payoutsPagination: action.pagination,
        // selectedPeriodId: action.idPeriod,
      };
    case actionConstants.FETCH_MODEL_TIERED_PAYOUT_ERROR:
      return {
        ...state,
        walletLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODEL_BONUS_PAYOUT:
      return {
        ...state,
        walletLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_BONUS_PAYOUT_SUCCESS:
      return {
        ...state,
        walletLoader: false,
        bonusPayout: action.bonusPayout,
        bonusPayoutPagination: action.pagination,
      };
    case actionConstants.FETCH_MODEL_BONUS_PAYOUT_ERROR:
      return {
        ...state,
        walletLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_TOTAL_PAYOUTS:
      return {
        ...state,
        walletLoader: true,
        error: null,
      };
    case actionConstants.FETCH_TOTAL_PAYOUTS_SUCCESS:
      return {
        ...state,
        walletLoader: false,
        totalPayouts: action.totalPayouts,
      };
    case actionConstants.FETCH_TOTAL_PAYOUTS_ERROR:
      return {
        ...state,
        walletLoader: false,
        error: action.error,
      };
    case actionConstants.SELECT_TRANSACTION:
      return {
        ...state,
        selectedTransaction: action.transaction,
        addedRefund: false,
      };
    case actionConstants.UNSELECT_TRANSACTION:
      return {
        ...state,
        selectedTransaction: null,
      };
    case actionConstants.ADD_REFUND:
      return {
        ...state,
        refundLoader: true,
        error: null,
      };
    case actionConstants.ADD_REFUND_SUCCESS:
      const newTransactions = state.transactions.map(transaction => {
        if (transaction.id === action.refundTransaction.id_transaction_refund) {
          transaction.is_refunded = true;
        }
        return transaction;
      });
      const newTransactionsBonus = state.transactionsBonus.map(transaction => {
        if (transaction.id === action.refundTransaction.id_transaction_refund) {
          transaction.is_refunded = true;
        }
        return transaction;
      });
      return {
        ...state,
        refundLoader: false,
        selectedTransaction: null,
        transactions: [...newTransactions],
        transactionsBonus: [...newTransactionsBonus],
        addedRefund: true,
      };
    case actionConstants.ADD_REFUND_ERROR:
      return {
        ...state,
        refundLoader: false,
        error: action.error,
      };
    case actionConstants.ADD_MODEL_CHARGEBACK:
      return {
        ...state,
        refundLoader: true,
        addedRefund: false,
        error: null,
      };
    case actionConstants.ADD_MODEL_CHARGEBACK_SUCCESS:
      return {
        ...state,
        refundLoader: false,
        transactionsPayoutAdjustments:
          state.currentPeriod.id === state.selectedPeriodId
            ? [action.transaction, ...state.transactionsPayoutAdjustments]
            : [...state.transactionsPayoutAdjustments],
        addedRefund: true,
      };
    case actionConstants.ADD_MODEL_CHARGEBACK_ERROR:
      return {
        ...state,
        refundLoader: false,
        error: action.error,
      };
    case actionConstants.ADD_MODEL_BONUS:
      return {
        ...state,
        refundLoader: true,
        addedRefund: false,
        error: null,
      };
    case actionConstants.ADD_MODEL_BONUS_SUCCESS:
      return {
        ...state,
        refundLoader: false,
        transactionsPayoutAdjustments:
          state.currentPeriod.id === state.selectedPeriodId
            ? [action.transaction, ...state.transactionsPayoutAdjustments]
            : [...state.transactionsPayoutAdjustments],
        addedRefund: true,
      };
    case actionConstants.ADD_MODEL_BONUS_ERROR:
      return {
        ...state,
        refundLoader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default modelsReducer;
