import _ from 'lodash';
import { refundTransaction } from './actions';
import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  error: null,
  models: [],
  modelsLoader: false,
  members: [],
  membersLoader: false,
  pagination: null,
  selectedTransaction: null,
  addedRefund: false,
  refundLoader: false,
};

const transactionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_TRANSACTIONS:
      return {
        ...state,
        loader: true,
        error: null,
        addedRefund: false,
      };
    case actionConstants.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        items: action.transactions,
        pagination: action.pagination,
        loader: false,
      };
    case actionConstants.FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODELS_FOR_SEARCH:
      return {
        ...state,
        modelsLoader: true,
      };
    case actionConstants.FETCH_MODELS_FOR_SEARCH_SUCCESS: {
      return {
        ...state,
        modelsLoader: false,
        models: action.models,
      };
    }
    case actionConstants.FETCH_MODELS_FOR_SEARCH_ERROR: {
      return {
        ...state,
        modelsLoader: false,
        error: action.error,
      };
    }
    case actionConstants.FETCH_MEMBERS_FOR_SEARCH:
      return {
        ...state,
        membersLoader: true,
      };
    case actionConstants.FETCH_MEMBERS_FOR_SEARCH_SUCCESS: {
      return {
        ...state,
        membersLoader: false,
        members: action.members,
      };
    }
    case actionConstants.FETCH_MEMBERS_FOR_SEARCH_ERROR: {
      return {
        ...state,
        membersLoader: false,
        error: action.error,
      };
    }
    case actionConstants.SELECT_TRANSACTION:
      return {
        ...state,
        selectedTransaction: action.transaction,
        addedRefund: false,
      };
    case actionConstants.UNSELECT_TRANSACTION:
      return {
        ...state,
        selectedTransaction: null,
      };
    case actionConstants.ADD_REFUND:
      return {
        ...state,
        refundLoader: true,
        error: null,
      };
    case actionConstants.ADD_REFUND_SUCCESS:
      const newTransactions = state.items.map(transaction => {
        if (transaction.id === action.refundTransaction.id_transaction_refund) {
          transaction.refunded = true;
        }
        return transaction;
      });
      return {
        ...state,
        refundLoader: false,
        selectedTransaction: null,
        items: [...newTransactions],
        addedRefund: true,
      };
    case actionConstants.ADD_REFUND_ERROR:
      return {
        ...state,
        refundLoader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
