export default {
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_ERROR: 'FETCH_ROLES_ERROR',
  FETCH_ROLE: 'FETCH_ROLE',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  FETCH_ROLE_ERROR: 'FETCH_ROLE_ERROR',
  UNSELECT_ROLE: 'UNSELECT_ROLE',
  SAVE_ROLE: 'SAVE_ROLE',
  SAVE_ROLE_SUCCESS: 'SAVE_ROLE_SUCCESS',
  SAVE_ROLE_ERROR: 'SAVE_ROLE_ERROR',
  DELETE_ROLE: 'DELETE_ROLE',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_ERROR: 'DELETE_ROLE_ERROR',
  UPDATE_ROLE: 'UPDATE_ROLE',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_ERROR: 'UPDATE_ROLE_ERROR',
  REMOVE_NOTIFICATIONS: 'REMOVE_NOTIFICATIONS',
};
