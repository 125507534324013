import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  models: [],
  modelsLoader: false,
  members: [],
  membersLoader: false,
  loader: false,
  error: null,
  pagination: null,
};

const chatReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_CHAT_LOGS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_CHAT_LOGS_SUCCESS:
      return {
        ...state,
        items: action.chatLogs,
        pagination: action.pagination,
        loader: false,
        error: null,
      };
    case actionConstants.FETCH_CHAT_LOGS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODELS_FOR_SEARCH:
      return {
        ...state,
        modelsLoader: true,
      };
    case actionConstants.FETCH_MODELS_FOR_SEARCH_SUCCESS: {
      return {
        ...state,
        modelsLoader: false,
        models: action.models,
      };
    }
    case actionConstants.FETCH_MODELS_FOR_SEARCH_ERROR: {
      return {
        ...state,
        modelsLoader: false,
        error: action.error,
      };
    }
    case actionConstants.FETCH_MEMBERS_FOR_SEARCH:
      return {
        ...state,
        membersLoader: true,
      };
    case actionConstants.FETCH_MEMBERS_FOR_SEARCH_SUCCESS: {
      return {
        ...state,
        membersLoader: false,
        members: action.members,
      };
    }
    case actionConstants.FETCH_MEMBERS_FOR_SEARCH_ERROR: {
      return {
        ...state,
        membersLoader: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
