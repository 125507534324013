export default {
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',
  SELECT_USER: 'SELECT_USER',
  UNSELECT_USER: 'UNSELECT_USER',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  ADD_USER: 'ADD_USER',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERROR: 'ADD_USER_ERROR',
  EDIT_USER: 'EDIT_USER',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_ERROR: 'EDIT_USER_ERROR',
  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
  CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_ERROR: 'CHANGE_USER_PASSWORD_ERROR',
};
