export default {
  FETCH_TRANSACTIONS: 'FETCH_TRANSACTIONS',
  FETCH_TRANSACTIONS_SUCCESS: 'FETCH_TRANSACTIONS_SUCCESS',
  FETCH_TRANSACTIONS_ERROR: 'FETCH_TRANSACTIONS_ERROR',
  FETCH_MODELS_FOR_SEARCH: 'FETCH_MODELS_FOR_SEARCH',
  FETCH_MODELS_FOR_SEARCH_SUCCESS: 'FETCH_MODELS_FOR_SEARCH_SUCCESS',
  FETCH_MODELS_FOR_SEARCH_ERROR: 'FETCH_MODELS_FOR_SEARCH_ERROR',
  FETCH_MEMBERS_FOR_SEARCH: 'FETCH_MEMBERS_FOR_SEARCH',
  FETCH_MEMBERS_FOR_SEARCH_SUCCESS: 'FETCH_MEMBERS_FOR_SEARCH_SUCCESS',
  FETCH_MEMBERS_FOR_SEARCH_ERROR: 'FETCH_MEMBERS_FOR_SEARCH_ERROR',
  SELECT_TRANSACTION: 'SELECT_TRANSACTION',
  UNSELECT_TRANSACTION: 'UNSELECT_TRANSACTION',
  ADD_REFUND: 'ADD_REFUND',
  ADD_REFUND_SUCCESS: 'ADD_REFUND_SUCCESS',
  ADD_REFUND_ERROR: 'ADD_REFUND_ERROR',
};
