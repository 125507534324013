export default {
  FETCH_CHAT_LOGS: 'FETCH_CHAT_LOGS',
  FETCH_CHAT_LOGS_SUCCESS: 'FETCH_CHAT_LOGS_SUCCESS',
  FETCH_CHAT_LOGS_ERROR: 'FETCH_CHAT_LOGS_ERROR',
  FETCH_MODELS_FOR_SEARCH: 'FETCH_MODELS_FOR_SEARCH',
  FETCH_MODELS_FOR_SEARCH_SUCCESS: 'FETCH_MODELS_FOR_SEARCH_SUCCESS',
  FETCH_MODELS_FOR_SEARCH_ERROR: 'FETCH_MODELS_FOR_SEARCH_ERROR',
  FETCH_MEMBERS_FOR_SEARCH: 'FETCH_MEMBERS_FOR_SEARCH',
  FETCH_MEMBERS_FOR_SEARCH_SUCCESS: 'FETCH_MEMBERS_FOR_SEARCH_SUCCESS',
  FETCH_MEMBERS_FOR_SEARCH_ERROR: 'FETCH_MEMBERS_FOR_SEARCH_ERROR',
};
