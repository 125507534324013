import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  selected: null,
  error: null,
  allFeatures: null,
  roleLoader: false,
  addedRole: false,
  updatedRole: false,
  deletedRole: false,
};

const rolesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_ROLES:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        items: action.roles,
        loader: false,
        error: null,
        allFeatures: action.allFeatures,
      };
    case actionConstants.FETCH_ROLES_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.FETCH_ROLE:
      return {
        ...state,
        roleLoader: true,
        selected: null,
      };
    case actionConstants.FETCH_ROLE_SUCCESS:
      return {
        ...state,
        roleLoader: false,
        error: null,
        selected: action.selected,
      };
    case actionConstants.FETCH_ROLE_ERROR:
      return {
        ...state,
        roleLoader: false,
        error: action.error,
      };
    case actionConstants.UNSELECT_ROLE:
      return {
        ...state,
        roleLoader: false,
        error: null,
        selected: null,
      };
    case actionConstants.SAVE_ROLE:
      return {
        ...state,
        roleLoader: true,
        error: null,
      };
    case actionConstants.SAVE_ROLE_SUCCESS:
      const newItems = [...state.items];
      newItems.push(action.role);
      return {
        ...state,
        roleLoader: false,
        selected: {
          role: action.role,
          roleFeatures: action.roleFeatures,
        },
        items: newItems,
        addedRole: true,
      };
    case actionConstants.SAVE_ROLE_ERROR:
      return {
        ...state,
        roleLoader: false,
        error: action.error,
      };
    case actionConstants.DELETE_ROLE:
      return {
        ...state,
        roleLoader: true,
        error: null,
      };
    case actionConstants.DELETE_ROLE_SUCCESS:
      const items = state.items.filter(i => i.id !== action.roleId);
      return {
        ...state,
        roleLoader: false,
        error: null,
        items: items,
        selected: null,
        deletedRole: true,
      };
    case actionConstants.DELETE_ROLE_ERROR:
      return {
        ...state,
        error: action.error,
        selected: null,
        roleLoader: false,
      };
    case actionConstants.UPDATE_ROLE:
      return {
        ...state,
        roleLoader: true,
        error: null,
      };
    case actionConstants.UPDATE_ROLE_SUCCESS:
      const newUpdatedItems = state.items.map(i => {
        if (i.id === action.role.id) {
          return action.role;
        } else {
          return i;
        }
      });
      return {
        ...state,
        roleLoader: false,
        selected: {
          role: action.role,
          roleFeatures: action.roleFeatures,
        },
        items: newUpdatedItems,
        updatedRole: true,
      };
    case actionConstants.UPDATE_ROLE_ERROR:
      return {
        ...state,
        roleLoader: false,
        error: action.error,
      };
    case actionConstants.REMOVE_NOTIFICATIONS:
      return {
        ...state,
        roleLoader: false,
        addedRole: false,
        updatedRole: false,
        deletedRole: false,
      };
    default:
      return state;
  }
};

export default rolesReducer;
