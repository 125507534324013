import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  selected: null,
  error: null,
  pagination: null,
  count: null,
};

const membersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_MEMBERS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        items: action.members,
        loader: false,
        pagination: action.pagination,
      };
    case actionConstants.FETCH_MEMBERS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SELECT_MEMBER:
      return {
        ...state,
        selected: action.member,
        loader: false,
        error: null,
      };
    case actionConstants.UNSELECT_MEMBER:
      return {
        ...state,
        selected: null,
        loader: false,
        error: null,
      };
    case actionConstants.FETCH_MEMBER_COUNT:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_MEMBER_COUNT_SUCCESS:
      return {
        ...state,
        loader: false,
        count: action.count,
        error: null,
      };
    case actionConstants.FETCH_MEMBER_COUNT_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default membersReducer;
