import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import { store } from './redux/store';
import { logout } from '@iso/redux/auth/actions';
import { setGlobalError } from '@iso/redux/app/actions';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const axiosInstance = axios.create({
  baseURL: siteConfig.apiUrl,
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

const refreshAuthLogic = failedRequest =>
  axiosInstance
    .post('/auth/refresh-token', {
      refreshToken: localStorage.getItem('refreshToken'),
    })
    .then(tokenRefreshResponse => {
      localStorage.setItem(
        'accessToken',
        tokenRefreshResponse.data.data.accessToken
      );
      localStorage.setItem(
        'refreshToken',
        tokenRefreshResponse.data.data.refreshToken
      );
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.data.accessToken;
      return Promise.resolve();
    })
    .catch(error => {
      store.dispatch(logout());
      return new Promise((resolve, reject) => {
        reject(error);
      });
    });

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

axiosInstance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    if (!error.response) {
      if (axios.isCancel(error)) {
        // canceled request
      } else {
        store.dispatch(
          setGlobalError({
            success: false,
            message: null,
            status: 500,
          })
        );
      }
    } else {
      store.dispatch(setGlobalError(error.response.data));
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
