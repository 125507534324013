import moment from 'moment';
import _ from 'lodash';

import axios from '../../../../packages/isomorphic/src/axios';
import {
  createQueryForBackend,
  getCurrentValueOfOneParam,
} from '@iso/lib/helpers/urlParams';
import transactionsFilters from '@iso/containers/Transactions/TransactionsURLfilters';
import siteConfig from '@iso/config/site.config';
import actionConstants from './constants';

export const getAllTransactions = () => async dispatch => {
  dispatch({ type: actionConstants.FETCH_TRANSACTIONS });
  try {
    const start_date = getCurrentValueOfOneParam('start_date');
    const start_time = getCurrentValueOfOneParam('start_time');

    const startDateString =
      start_date && start_time
        ? moment(`${start_date} ${start_time}`)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss.SSS')
        : start_date
        ? moment(start_date)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss.SSS')
        : null;

    const end_date = getCurrentValueOfOneParam('end_date');
    const end_time = getCurrentValueOfOneParam('end_time');

    const endDateString =
      end_date && end_time
        ? moment(`${end_date} ${end_time}`)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss.SSS')
        : end_date
        ? moment(end_date)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss.SSS')
        : null;

    let url = createQueryForBackend(
      'wallet/all-transactions',
      transactionsFilters
    );

    if (startDateString) {
      if (url === 'wallet/all-transactions') {
        url += `?start_date_time=${startDateString}`;
      } else {
        url += `&start_date_time=${startDateString}`;
      }
    }

    if (endDateString) {
      if (url === 'wallet/all-transactions') {
        url += `?end_date_time=${endDateString}`;
      } else {
        url += `&end_date_time=${endDateString}`;
      }
    }

    const response = await axios.get(url);
    const transactions = response.data.data.transactions;
    const pagination = response.data.data.pagination;
    dispatch({
      type: actionConstants.FETCH_TRANSACTIONS_SUCCESS,
      transactions,
      pagination,
    });
  } catch (error) {
    dispatch({
      type: actionConstants.FETCH_TRANSACTIONS_ERROR,
      error,
    });
  }
};

export const getModelsForSearch = () => async dispatch => {
  dispatch({ type: actionConstants.FETCH_MODELS_FOR_SEARCH });
  try {
    const url = createQueryForBackend('wallet/model/search', [
      'modelScreenName',
    ]);
    let response = await axios.get(url);
    let models = response.data.data.models;
    dispatch({
      type: actionConstants.FETCH_MODELS_FOR_SEARCH_SUCCESS,
      models: models,
    });
  } catch (error) {
    dispatch({
      type: actionConstants.FETCH_MODELS_FOR_SEARCH_ERROR,
      error,
    });
  }
};

export const getMembersForSearch = () => async dispatch => {
  dispatch({ type: actionConstants.FETCH_MEMBERS_FOR_SEARCH });
  try {
    const url = createQueryForBackend('wallet/member/search', ['member_name']);
    const response = await axios.get(url);
    const members = response.data.data.members;
    dispatch({
      type: actionConstants.FETCH_MEMBERS_FOR_SEARCH_SUCCESS,
      members: members,
    });
  } catch (error) {
    dispatch({
      type: actionConstants.FETCH_MEMBERS_FOR_SEARCH_ERROR,
      error,
    });
  }
};

export const downloadTransactionsCSV = type => async dispatch => {
  const token = localStorage.getItem('accessToken');
  let url = createQueryForBackend(
    'wallet/all-transactions',
    transactionsFilters
  );
  let string = '?';
  if (url !== 'wallet/all-transactions') {
    string = '&';
  }

  url += `${string}token=${token}&file_export=${type}`;
  const downloadUrl = `${siteConfig.apiUrl}${url}`;
  window.open(downloadUrl, '_blank');
};

export const selectTransaction = transaction => dispatch => {
  if (transaction) {
    dispatch({
      type: actionConstants.SELECT_TRANSACTION,
      transaction,
    });
  } else {
    dispatch({
      type: actionConstants.UNSELECT_TRANSACTION,
    });
  }
};

export const refundTransaction = (
  transactionId,
  refundInfo
) => async dispatch => {
  dispatch({ type: actionConstants.ADD_REFUND });
  try {
    const response = await axios.post(
      `wallet/refund/transaction/${transactionId}`,
      refundInfo
    );

    dispatch({
      type: actionConstants.ADD_REFUND_SUCCESS,
      refundTransaction: response.data.data.refundTransaction,
    });
  } catch (error) {
    dispatch({
      type: actionConstants.ADD_REFUND_ERROR,
      error,
    });
  }
};
