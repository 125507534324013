import actionConstants from './constants';

export const INIT_STATE = {
  debitTransactions: [],
  creditTransactions: [],
  debitLoader: false,
  creditLoader: false,
  error: null,
  debitPagination: null,
  creditPagination: null,
  selectedTransaction: null,
  addedRefund: false,
  refundLoader: false,
  tokenSpentLoader: false,
  tokenBoughtLoader: false,
  totalTokensSpent: null,
  totalTokensBought: null,
};

const memberTransactionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_MEMBER_CREDIT_TRANSACTIONS:
      return {
        ...state,
        creditLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MEMBER_CREDIT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        creditTransactions: action.transactions,
        creditLoader: false,
        creditPagination: action.pagination,
      };
    case actionConstants.FETCH_MEMBER_CREDIT_TRANSACTIONS_ERROR:
      return {
        ...state,
        creditLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MEMBER_DEBIT_TRANSACTIONS:
      return {
        ...state,
        debitLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MEMBER_DEBIT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        debitTransactions: action.transactions,
        debitLoader: false,
        debitPagination: action.pagination,
      };
    case actionConstants.FETCH_MEMBER_DEBIT_TRANSACTIONS_ERROR:
      return {
        ...state,
        debitLoader: false,
        error: action.error,
      };
    case actionConstants.SELECT_TRANSACTION:
      return {
        ...state,
        selectedTransaction: action.transaction,
        addedRefund: false,
      };
    case actionConstants.UNSELECT_TRANSACTION:
      return {
        ...state,
        selectedTransaction: null,
      };
    case actionConstants.ADD_REFUND:
      return {
        ...state,
        refundLoader: true,
        error: null,
      };
    case actionConstants.ADD_REFUND_SUCCESS:
      const newTransactions = state.debitTransactions.map(transaction => {
        if (transaction.id === action.refundTransaction.id_transaction_refund) {
          transaction.is_refunded = true;
        }
        return transaction;
      });
      return {
        ...state,
        refundLoader: false,
        selectedTransaction: null,
        debitTransactions: newTransactions,
        addedRefund: true,
      };
    case actionConstants.ADD_REFUND_ERROR:
      return {
        ...state,
        refundLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_TOTAL_TOKEN_SPENT:
      return {
        ...state,
        tokenSpentLoader: true,
        error: null,
      };
    case actionConstants.FETCH_TOTAL_TOKEN_SPENT_SUCCESS:
      return {
        ...state,
        totalTokensSpent: action.tokensSpent,
        tokenSpentLoader: false,
      };
    case actionConstants.FETCH_TOTAL_TOKEN_SPENT_ERROR:
      return {
        ...state,
        tokenSpentLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_TOTAL_TOKEN_BOUGHT:
      return {
        ...state,
        tokenBoughtLoader: true,
        error: null,
      };
    case actionConstants.FETCH_TOTAL_TOKEN_BOUGHT_SUCCESS:
      return {
        ...state,
        totalTokensBought: action.tokensBought,
        tokenBoughtLoader: false,
      };
    case actionConstants.FETCH_TOTAL_TOKEN_BOUGHT_ERROR:
      return {
        ...state,
        tokenBoughtLoader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default memberTransactionsReducer;
