export default {
  FETCH_GIFTS: 'FETCH_GIFTS',
  FETCH_GIFTS_SUCCESS: 'FETCH_GIFTS_SUCCESS',
  FETCH_GIFTS_ERROR: 'FETCH_GIFTS_ERROR',
  SELECT_GIFT: 'SELECT_GIFT',
  UNSELECT_GIFT: 'UNSELECT_GIFT',
  DELETE_GIFT: 'DELETE_GIFT',
  DELETE_GIFT_SUCCESS: 'DELETE_GIFT_SUCCESS',
  DELETE_GIFT_ERROR: 'DELETE_GIFT_ERROR',
  ADD_GIFT: 'ADD_GIFT',
  ADD_GIFT_SUCCESS: 'ADD_GIFT_SUCCESS',
  ADD_GIFT_ERROR: 'ADD_GIFT_ERROR',
  EDIT_GIFT: 'EDIT_GIFT',
  EDIT_GIFT_SUCCESS: 'EDIT_GIFT_SUCCESS',
  EDIT_GIFT_ERROR: 'EDIT_GIFT_ERROR',
};
