export default {
  FETCH_ONLINE_MODELS: 'FETCH_ONLINE_MODELS',
  FETCH_ONLINE_MODELS_SUCCESS: 'FETCH_ONLINE_MODELS_SUCCESS',
  FETCH_ONLINE_MODELS_ERROR: 'FETCH_ONLINE_MODELS_ERROR',
  FETCH_SEARCH_MODELS: 'FETCH_SEARCH_MODELS',
  FETCH_SEARCH_MODELS_SUCCESS: 'FETCH_SEARCH_MODELS_SUCCESS',
  FETCH_SEARCH_MODELS_ERROR: 'FETCH_SEARCH_MODELS_ERROR',
  FETCH_SELECTED_MODEL: 'FETCH_SELECTED_MODEL',
  FETCH_SELECTED_MODEL_SUCCESS: 'FETCH_SELECTED_MODEL_SUCCESS',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  FETCH_SELECTED_MODEL_ERROR: 'FETCH_SELECTED_MODEL_ERROR',
  FETCH_MESSAGES_HISTORY: 'FETCH_MESSAGE_HISTORY',
  FETCH_MESSAGES_HISTORY_SUCCESS: 'FETCH_MESSAGE_HISTORY_SUCCESS',
  FETCH_MESSAGES_HISTORY_ERROR: 'FETCH_MESSAGE_HISTORY_ERROR',
  FETCH_PRIVATE_ROOMS: '	FETCH_PRIVATE_ROOMS',
  FETCH_PRIVATE_ROOMS_SUCCESS: '	FETCH_PRIVATE_ROOMS_SUCCESS',
  FETCH_PRIVATE_ROOMS_ERROR: '	FETCH_PRIVATE_ROOMS_ERROR',
  FETCH_CURRENT_ROOM: '	FETCH_CURRENT_ROOM',
  FETCH_CURRENT_ROOM_SUCCESS: '	FETCH_CURRENT_ROOM_SUCCESS',
  FETCH_CURRENT_ROOM_ERROR: '	FETCH_CURRENT_ROOM_ERROR',
  SET_SELECTED_MESSAGE: 'SET_SELECTED_MESSAGE',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_ERROR: 'UPDATE_NOTIFICATION_ERROR',
  ADD_PUBLIC_ROOM: 'ADD_PUBLIC_ROOM',
  ADD_PRIVATE_ROOM: 'ADD_PRIVATE_ROOM',
  REMOVE_PUBLIC_ROOM: 'REMOVE_PUBLIC_ROOM',
  REMOVE_PRIVATE_ROOM: 'REMOVE_PRIVATE_ROOM',
  ADD_NEW_MESSAGE: 'ADD_NEW_MESSAGE',
  RESET_MODEL: 'RESET_MODEL',
  UPDATE_ROOM_MEMBERS: 'UPDATE_ROOM_MEMBERS',
  FETCH_CURRENT_MESSAGES_HISTORY: 'FETCH_CURRENT_MESSAGES_HISTORY',
  FETCH_CURRENT_MESSAGES_HISTORY_SUCCESS:
    'FETCH_CURRENT_MESSAGES_HISTORY_SUCCESS',
  FETCH_CURRENT_MESSAGES_HISTORY_ERROR: 'FETCH_CURRENT_MESSAGES_HISTORY_ERROR',
  FETCH_MORE_MESSAGES_HISTORY: 'FETCH_MORE_MESSAGE_HISTORY',
  FETCH_MORE_MESSAGES_HISTORY_SUCCESS: 'FETCH_MORE_MESSAGE_HISTORY_SUCCESS',
  FETCH_MORE_MESSAGES_HISTORY_ERROR: 'FETCH_MORE_MESSAGE_HISTORY_ERROR',
  UNSELECT_MODEL: 'UNSELECT_MODEL_ROOM',
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_ERROR: 'FETCH_NOTIFICATIONS_ERROR',
  FETCH_MORE_NOTIFICATIONS: 'FETCH_MORE_NOTIFICATIONS',
  FETCH_MORE_NOTIFICATIONS_SUCCESS: 'FETCH_MORE_NOTIFICATIONS_SUCCESS',
  FETCH_MORE_NOTIFICATIONS_ERROR: 'FETCH_MORE_NOTIFICATIONS_ERROR',
  SET_LAST_SEEN_MESSAGE_TO_NULL: 'SET_LAST_SEEN_MESSAGE_TO_NULL',
};
