import _ from 'lodash';

export function getUrlWithParams(paramsToGet, params) {
  let url = window.location.pathname;
  let string = '?';

  for (let i = 0; i < (paramsToGet && paramsToGet.length); i += 1) {
    const value = params.get(paramsToGet[i]);
    if (value) {
      if (paramsToGet[i] !== 'pageSize' || value <= 200) {
        url += `${string + paramsToGet[i]}=${value}`;
        string = '&';
      }
    }
  }
  return url;
}

export function getParamsFromUrl(paramsToGet) {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);
  let obj = {};

  for (let i = 0; i < paramsToGet.length; i += 1) {
    const value = params.get(paramsToGet[i]);
    if (value) {
      obj[paramsToGet[i]] = value;
    }
  }
  return obj;
}

export function updateUrlWithParam(history, name, value, filters) {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);
  if (value) {
    params.set(name, value);
  } else {
    params.delete(name);
  }
  const url = getUrlWithParams(filters, params);
  history.push(url);
}

export function cleanUrl(paramsToGet) {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);
  const url = getUrlWithParams(paramsToGet, params);
  return url;
}

export function checkfFiltersInUrl(paramsToGet) {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);

  for (let i = 0; i < paramsToGet.length; i += 1) {
    if (paramsToGet[i] !== 'page' && paramsToGet[i] !== 'pageSize') {
      const value = params.get(paramsToGet[i]);
      if (value) {
        return true;
      }
    }
  }

  return false;
}

export function getCurrentTab() {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);
  const tab = params.get('tab');
  return tab;
}

export function createQueryForBackend(startUrl, paramsToGet, staticParams) {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);

  let url = startUrl;
  let string = '?';

  for (let i = 0; i < paramsToGet.length; i += 1) {
    if (staticParams && staticParams[paramsToGet[i]]) {
      url += `${string + paramsToGet[i]}=${staticParams[paramsToGet[i]]}`;
      string = '&';
    } else {
      const value = params.get(paramsToGet[i]);
      if (value) {
        url += `${string + paramsToGet[i]}=${value}`;
        string = '&';
      }
    }
  }

  return url;
}

export function getCurrentValueOfOneParam(paramName) {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);
  const paramValue = params.get(paramName);
  return paramValue;
}

export function getCurrentValueOfManyParams(paramsNames) {
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);

  const values = [];
  for (let i = 0; i < paramsNames.length; i += 1) {
    const value = params.get(paramsNames[i]);
    if (value) {
      if (value === 'true' || value === 'false') {
        values.push(paramsNames[i]);
      } else {
        values.push(value);
      }
    }
  }
  return values;
}
