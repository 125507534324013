export default {
  FETCH_TOKEN_PACKAGES: 'FETCH_TOKEN_PACKAGES',
  FETCH_TOKEN_PACKAGES_SUCCESS: 'FETCH_TOKEN_PACKAGES_SUCCESS',
  FETCH_TOKEN_PACKAGES_ERROR: 'FETCH_TOKEN_PACKAGES_ERROR',
  SELECT_TOKEN_PACKAGE: 'SELECT_TOKEN_PACKAGE',
  UNSELECT_TOKEN_PACKAGE: 'UNSELECT_TOKEN_PACKAGE',
  DELETE_TOKEN_PACKAGE: 'DELETE_TOKEN_PACKAGE',
  DELETE_TOKEN_PACKAGE_SUCCESS: 'DELETE_TOKEN_PACKAGE_SUCCESS',
  DELETE_TOKEN_PACKAGE_ERROR: 'DELETE_TOKEN_PACKAGE_ERROR',
  ADD_TOKEN_PACKAGE: 'ADD_TOKEN_PACKAGE',
  ADD_TOKEN_PACKAGE_SUCCESS: 'ADD_TOKEN_PACKAGE_SUCCESS',
  ADD_TOKEN_PACKAGE_ERROR: 'ADD_TOKEN_PACKAGE_ERROR',
  EDIT_TOKEN_PACKAGE: 'EDIT_TOKEN_PACKAGE',
  EDIT_TOKEN_PACKAGE_SUCCESS: 'EDIT_TOKEN_PACKAGE_SUCCESS',
  EDIT_TOKEN_PACKAGE_ERROR: 'EDIT_TOKEN_PACKAGE_ERROR',
};
