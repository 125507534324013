export default {
  FETCH_MODEL_CURRENT_PERIOD: 'FETCH_MODEL_CURRENT_PERIOD',
  FETCH_MODEL_CURRENT_PERIOD_SUCCESS: 'FETCH_MODEL_CURRENT_PERIOD_SUCCESS',
  FETCH_MODEL_CURRENT_PERIOD_ERROR: 'FETCH_MODEL_CURRENT_PERIOD_ERROR',
  FETCH_MODEL_TRANSACTIONS: 'FETCH_MODEL_TRANSACTIONS',
  FETCH_MODEL_TRANSACTIONS_SUCCESS: 'FETCH_MODEL_TRANSACTIONS_SUCCESS',
  FETCH_MODEL_TRANSACTIONS_ERROR: 'FETCH_MODEL_TRANSACTIONS_ERROR',

  FETCH_MODEL_TRANSACTIONS_FIXED: 'FETCH_MODEL_TRANSACTIONS_FIXED',
  FETCH_MODEL_TRANSACTIONS_FIXED_SUCCESS:
    'FETCH_MODEL_TRANSACTIONS_FIXED_SUCCESS',
  FETCH_MODEL_TRANSACTIONS_FIXED_ERROR: 'FETCH_MODEL_TRANSACTIONS_FIXED_ERROR',

  FETCH_MODEL_TRANSACTIONS_BONUS: 'FETCH_MODEL_TRANSACTIONS_BONUS',
  FETCH_MODEL_TRANSACTIONS_BONUS_SUCCESS:
    'FETCH_MODEL_TRANSACTIONS_BONUS_SUCCESS',
  FETCH_MODEL_TRANSACTIONS_BONUS_ERROR: 'FETCH_MODEL_TRANSACTIONS_BONUS_ERROR',

  FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT:
    'FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT',
  FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT_SUCCESS:
    'FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT_SUCCESS',
  FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT_ERROR:
    'FETCH_MODEL_TRANSACTIONS_PAYOUT_ADJUSTMENT_ERROR',

  FETCH_MODEL_TIERED_PAYOUT: 'FETCH_MODEL_TIERED_PAYOUT',
  FETCH_MODEL_TIERED_PAYOUT_SUCCESS: 'FETCH_MODEL_TIERED_PAYOUT_SUCCESS',
  FETCH_MODEL_TIERED_PAYOUT_ERROR: 'FETCH_MODEL_TIERED_PAYOUT_ERROR',
  FETCH_MODEL_BONUS_PAYOUT: 'FETCH_MODEL_BONUS_PAYOUT',
  FETCH_MODEL_BONUS_PAYOUT_SUCCESS: 'FETCH_MODEL_BONUS_PAYOUT_SUCCESS',
  FETCH_MODEL_BONUS_PAYOUT_ERROR: 'FETCH_MODEL_BONUS_PAYOUT_ERROR',
  FETCH_TOTAL_PAYOUTS: 'FETCH_TOTAL_PAYOUTS',
  FETCH_TOTAL_PAYOUTS_SUCCESS: 'FETCH_TOTAL_PAYOUTS_SUCCESS',
  FETCH_TOTAL_PAYOUTS_ERROR: 'FETCH_TOTAL_PAYOUTS_ERROR',
  SELECT_TRANSACTION: 'SELECT_TRANSACTION',
  UNSELECT_TRANSACTION: 'UNSELECT_TRANSACTION',
  ADD_REFUND: 'ADD_REFUND',
  ADD_REFUND_SUCCESS: 'ADD_REFUND_SUCCESS',
  ADD_REFUND_ERROR: 'ADD_REFUND_ERROR',
  ADD_MODEL_CHARGEBACK: 'ADD_MODEL_CHARGEBACK',
  ADD_MODEL_CHARGEBACK_SUCCESS: 'ADD_MODEL_CHARGEBACK_SUCCESS',
  ADD_MODEL_CHARGEBACK_ERROR: 'ADD_MODEL_CHARGEBACK_ERROR',
  ADD_MODEL_BONUS: 'ADD_MODEL_BONUS',
  ADD_MODEL_BONUS_SUCCESS: 'ADD_MODEL_BONUS_SUCCESS',
  ADD_MODEL_BONUS_ERROR: 'ADD_MODEL_BONUS_ERROR',
};
