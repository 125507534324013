export default {
  FETCH_MEMBERS: 'FETCH_MEMBERS',
  FETCH_MEMBERS_SUCCESS: 'FETCH_MEMBERS_SUCCESS',
  FETCH_MEMBERS_ERROR: 'FETCH_MEMBERS_ERROR',
  SELECT_MEMBER: 'SELECT_MEMBER',
  UNSELECT_MEMBER: 'UNSELECT_MEMBER',
  FETCH_MEMBER_COUNT: 'FETCH_MEMBER_COUNT',
  FETCH_MEMBER_COUNT_SUCCESS: 'FETCH_MEMBER_COUNT_SUCCESS',
  FETCH_MEMBER_COUNT_ERROR: 'FETCH_MEMBER_COUNT_ERROR',
};
