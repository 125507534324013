import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import gifts from '@iso/redux/utils/gifts/reducer';
import tokenPackages from '@iso/redux/utils/token-packages/reducer';
import tips from '@iso/redux/utils/tips/reducer';
import models from '@iso/redux/models/reducer';
import members from '@iso/redux/members/reducer';
import studios from '@iso/redux/studios/reducer';
import memberTransactions from '@iso/redux/memberTransactions/reducer';
import dashboard from '@iso/redux/dashboard/reducer';
import modelTransactions from '@iso/redux/modelTransactions/reducer';
import users from '@iso/redux/users/reducer';
import chat from '@iso/redux/chat/chat/reducer';
import blockedWords from '@iso/redux/chat/blockedWords/reducer';
import chatMonitoring from '@iso/redux/chat/chatMonitoring/reducer';
import earnings from '@iso/redux/earnings/reducer';
import transactions from '@iso/redux/transactions/reducer';
import userSettings from '@iso/redux/settings/reducer';
import roles from '@iso/redux/roles/reducer';
import variances from '@iso/redux/variances/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  users,
  models,
  modelTransactions,
  members,
  memberTransactions,
  studios,
  earnings,
  dashboard,
  chat,
  blockedWords,
  chatMonitoring,
  gifts,
  tokenPackages,
  tips,
  userSettings,
  transactions,
  roles,
  variances,
});
