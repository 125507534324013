import _ from 'lodash';
import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  selected: null,
  error: null,
  addedTip: false,
  editedTip: false,
  deletedTip: false,
};

const tipsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_TIPS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_TIPS_SUCCESS:
      return {
        ...state,
        items: action.tips,
        loader: false,
      };
    case actionConstants.FETCH_TIPS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SELECT_TIP:
      return {
        ...state,
        selected: action.tip,
      };
    case actionConstants.ADD_TIP:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.ADD_TIP_SUCCESS:
      return {
        ...state,
        loader: false,
        items: [...state.items, action.tip],
        addedTip: true,
      };
    case actionConstants.ADD_TIP_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.EDIT_TIP:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.EDIT_TIP_SUCCESS:
      const changedItems = [...state.items];
      const changeIndex = changedItems.findIndex(p => p.id === action.tip.id);
      changedItems[changeIndex] = action.tip;
      return {
        ...state,
        loader: false,
        items: changedItems,
        editedTip: true,
      };
    case actionConstants.EDIT_TIP_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.DELETE_TIP:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.DELETE_TIP_SUCCESS:
      const newItems = _.filter(state.items, i => i.id !== action.tip.id);
      return {
        ...state,
        loader: false,
        selected: null,
        items: newItems,
        deletedTip: true,
      };
    case actionConstants.DELETE_TIP_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.UNSELECT_TIP:
      return {
        ...state,
        selected: null,
        addedTip: false,
        editedTip: false,
        deletedTip: false,
        error: null,
      };
    default:
      return state;
  }
};

export default tipsReducer;
