import actionConstants from './constants';
import _ from 'lodash';

export const INIT_STATE = {
  onlineModels: [],
  onlineModelsLoader: false,
  searchModels: [],
  error: null,
  selectedModel: null,
  selectedModelLoader: false,
  activeTab: 'PUBLIC',
  messageHistoryLoader: false,
  messageHistory: [],
  currentMessages: [],
  currentMessagesLoader: false,
  privateRooms: [],
  privateRoomsLoader: false,
  selectedPrivateRoom: null,
  messageMoreHistoryLoader: false,
  selectedMessage: null,
  currentRoom: [],
  onlineMembers: [],
  moreHistoryMessagesBackward: false,
  moreHistoryMessagesForward: false,
  notifications: [],
  notificationsLoader: false,
  moreNotificationsForward: false,
  moreNotificationBackward: false,
  moreNotificationsLoader: false,
  firstNotReadMessage: null,
};

const chatMonitoringReducer = (state = INIT_STATE, action) => {
  let newRooms = [];
  let newSelectedModel = state.selectedModel;
  let newNotifications = [];
  switch (action.type) {
    case actionConstants.FETCH_ONLINE_MODELS:
      return {
        ...state,
        onlineModelsLoader: true,
      };
    case actionConstants.FETCH_ONLINE_MODELS_SUCCESS:
      const newOnlineModels = [
        ...Object.values(action.models).sort((a, b) =>
          a.private ? -1 : b.private ? 1 : 0
        ),
      ];
      return {
        ...state,
        onlineModelsLoader: false,
        onlineModels: newOnlineModels,
        error: null,
      };
    case actionConstants.FETCH_ONLINE_MODELS_ERROR:
      return {
        ...state,
        onlineModelsLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_SELECTED_MODEL: {
      return {
        ...state,
        selectedModelLoader: true,
      };
    }
    case actionConstants.FETCH_SELECTED_MODEL_SUCCESS:
      return {
        ...state,
        selectedModel: {
          ...action.model,
          id: action.idModel,
        },
        selectedModelLoader: false,
        moreHistoryMessagesForward: false,
        moreHistoryMessagesBackward: false,
        activeTab: action.tabUrl
          ? action.tabUrl
          : action.model.private
          ? 'PRIVATE'
          : 'PUBLIC',
      };
    case actionConstants.FETCH_SELECTED_MODEL_ERROR:
      return {
        ...state,
        error: action.error,
        selectedModelLoader: false,
      };
    case actionConstants.UNSELECT_MODEL:
      return {
        ...state,
        selectedModel: null,
      };
    case actionConstants.FETCH_SEARCH_MODELS:
      return {
        ...state,
      };
    case actionConstants.FETCH_SEARCH_MODELS_SUCCESS:
      return {
        ...state,
        searchModels: action.models,
        error: null,
      };
    case actionConstants.FETCH_SEARCH_MODELS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actionConstants.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case actionConstants.FETCH_MESSAGES_HISTORY:
      return {
        ...state,
        messageHistoryLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MESSAGES_HISTORY_SUCCESS:
      return {
        ...state,
        messageHistoryLoader: false,
        messageHistory: action.messages,
        selectedPrivateRoom: action.selectedRoom || null,
        error: null,
        moreHistoryMessagesForward:
          action.moreHistoryMessagesForward !== null
            ? action.moreHistoryMessagesForward
            : state.moreHistoryMessagesForward,
        moreHistoryMessagesBackward:
          action.moreHistoryMessagesBackward !== null
            ? action.moreHistoryMessagesBackward
            : state.moreHistoryMessagesBackward,
        firstNotReadMessage: action.firstNotReadMessage,
      };
    case actionConstants.FETCH_MESSAGES_HISTORY_ERROR:
      return {
        ...state,
        messageHistoryLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_CURRENT_MESSAGES_HISTORY:
      return {
        ...state,
        currentMessagesLoader: true,
        error: null,
      };
    case actionConstants.FETCH_CURRENT_MESSAGES_HISTORY_SUCCESS:
      return {
        ...state,
        currentMessagesLoader: false,
        currentMessages: [...action.messages],
      };
    case actionConstants.FETCH_CURRENT_MESSAGES_HISTORY_ERROR:
      return {
        ...state,
        currentMessagesLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MORE_MESSAGES_HISTORY:
      return {
        ...state,
        messageMoreHistoryLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MORE_MESSAGES_HISTORY_SUCCESS:
      let newMessages = [];
      if (action.showMore === 'forward') {
        newMessages = _.uniqBy(
          _.concat(state.messageHistory, action.messages),
          'id'
        );
      }
      if (action.showMore === 'backward') {
        newMessages = _.concat(action.messages, state.messageHistory);
      }
      return {
        ...state,
        messageMoreHistoryLoader: false,
        messageHistory: newMessages,
        selectedPrivateRoom: action.selectedRoom || null,
        error: null,
        selectedMessage: null,
        moreHistoryMessagesForward:
          action.moreHistoryMessagesForward !== null
            ? action.moreHistoryMessagesForward
            : state.moreHistoryMessagesForward,
        moreHistoryMessagesBackward:
          action.moreHistoryMessagesBackward !== null
            ? action.moreHistoryMessagesBackward
            : state.moreHistoryMessagesBackward,
      };
    case actionConstants.FETCH_MORE_MESSAGES_HISTORY_ERROR:
      return {
        ...state,
        messageMoreHistoryLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_PRIVATE_ROOMS:
      return {
        ...state,
        privateRoomsLoader: true,
        error: null,
        moreHistoryMessagesForward: false,
        moreHistoryMessagesBackward: false,
      };
    case actionConstants.FETCH_PRIVATE_ROOMS_SUCCESS:
      return {
        ...state,
        privateRoomsLoader: false,
        privateRooms: action.rooms,
        error: null,
        selectedPrivateRoom: null,
      };
    case actionConstants.FETCH_PRIVATE_ROOMS_ERROR:
      return {
        ...state,
        privateRoomsLoader: false,
        error: action.error,
      };
    case actionConstants.SET_SELECTED_MESSAGE:
      return {
        ...state,
        selectedMessage: action.selectedMessage,
      };
    case actionConstants.FETCH_CURRENT_ROOM:
      return {
        ...state,
        currentMessagesLoader: true,
        error: null,
      };
    case actionConstants.FETCH_CURRENT_ROOM_SUCCESS:
      return {
        ...state,
        currentMessagesLoader: false,
        error: null,
        onlineMembers: action.onlineMembers,
        currentRoom: action.currentRoom,
      };
    case actionConstants.FETCH_CURRENT_ROOM_ERROR:
      return {
        ...state,
        currentMessagesLoader: false,
        error: action.error,
      };
    case actionConstants.ADD_NOTIFICATION:
      newNotifications = [...state.notifications];
      newNotifications.unshift({
        ...action.notification,
        is_new: true,
      });
      return {
        ...state,
        notifications: newNotifications,
      };
    case actionConstants.UPDATE_NOTIFICATION_SUCCESS: {
      const updatedNotifications = state.notifications.map(n => {
        if (n.id_notification === action.idNotification) {
          return {
            ...n,
            read: true,
          };
        } else {
          return n;
        }
      });
      return {
        ...state,
        notifications: updatedNotifications,
      };
    }
    case actionConstants.ADD_PUBLIC_ROOM:
      const rooms = [...state.onlineModels];
      let index = -1;
      if (rooms.length > 0) {
        index = rooms.findIndex(room => room.id === action.room.id);
      }
      if (index > -1) {
        rooms[index] = {
          ...rooms[index],
          public: true,
          public_count: rooms[index].public_count || 0,
        };
      } else {
        rooms.push(action.room);
      }

      if (state.selectedModel && action.room.id === state.selectedModel.id) {
        newSelectedModel = {
          ...state.selectedModel,
          public: true,
          public_count: state.selectedModel.public_count || 0,
        };
      }
      return {
        ...state,
        onlineModels: rooms,
        selectedModel: newSelectedModel,
      };
    case actionConstants.ADD_PRIVATE_ROOM:
      let newRoom = { ...action.room };
      for (let i = 0; i < state.onlineModels.length; i += 1) {
        if (state.onlineModels[i].id !== newRoom.id) {
          newRooms.push(state.onlineModels[i]);
        } else {
          newRoom = {
            ...state.onlineModels[i],
            private: true,
            private_count: state.onlineModels[i].private_count || 0,
          };
        }
      }
      newRooms.unshift(newRoom);
      if (state.selectedModel && action.room.id === state.selectedModel.id) {
        newSelectedModel = {
          ...state.selectedModel,
          private: true,
          private_count: state.selectedModel.private_count || 0,
        };
      }

      return {
        ...state,
        onlineModels: newRooms,
        selectedModel: newSelectedModel,
      };
    case actionConstants.REMOVE_PUBLIC_ROOM:
      state.onlineModels.forEach(room => {
        if (room.id !== action.id) {
          newRooms.push(room);
        } else {
          if (room.private) {
            newRooms.push({ ...room, public: false, public_count: null });
          }
        }
      });
      if (state.selectedModel && action.id === state.selectedModel.id) {
        newSelectedModel = {
          ...state.selectedModel,
          public: false,
          public_count: null,
        };
      }
      return {
        ...state,
        onlineModels: newRooms,
        selectedModel: newSelectedModel,
      };
    case actionConstants.REMOVE_PRIVATE_ROOM:
      for (let i = state.onlineModels.length - 1; i >= 0; i -= 1) {
        if (state.onlineModels[i].id !== action.id) {
          newRooms.unshift(state.onlineModels[i]);
        } else {
          if (state.onlineModels[i].public) {
            newRooms.push({
              ...state.onlineModels[i],
              private: false,
              private_count: null,
            });
          }
        }
      }
      if (state.selectedModel && action.id === state.selectedModel.id) {
        newSelectedModel = {
          ...state.selectedModel,
          private: false,
          private_count: null,
        };
      }
      return {
        ...state,
        onlineModels: newRooms,
        selectedModel: newSelectedModel,
      };
    case actionConstants.ADD_NEW_MESSAGE:
      return {
        ...state,
        currentMessages: [...state.currentMessages, action.message],
      };
    case actionConstants.SET_LAST_SEEN_MESSAGE_TO_NULL:
      return {
        ...state,
        firstNotReadMessage: null,
      };
    case actionConstants.RESET_MODEL:
      return {
        ...state,
        currentMessages: [],
        onlineMembers: [],
        currentRoom: null,
        messageHistory: [],
        privateRooms: [],
        selectedPrivateRoom: null,
        moreHistoryMessagesBackward: false,
        moreHistoryMessagesForward: false,
        selectedMesssage: null,
      };
    case actionConstants.UPDATE_ROOM_MEMBERS:
      let newMembers = [...state.onlineMembers];
      newRooms =
        state.onlineModels &&
        state.onlineModels.map(room => {
          if (room.id === action.modelId) {
            return {
              ...room,
              [`${action.roomType}_count`]: action.numberOfOnlineMembers,
            };
          } else {
            return room;
          }
        });
      if (
        state.currentRoom &&
        state.currentRoom.id === action.modelId &&
        state.currentRoom.type === action.roomType
      ) {
        if (action.member.action === 'joined') {
          if (!newMembers.find(member => member.id === action.member.id)) {
            newMembers.push(action.member);
          }
        } else {
          newMembers = state.onlineMembers.filter(
            member => member.id !== action.member.id
          );
        }
      }
      let newModelHelper = state.selectedModel;
      if (state.selectedModel && state.selectedModel.id === action.modelId) {
        newModelHelper = {
          ...state.selectedModel,
          [`${action.roomType}_count`]: action.numberOfOnlineMembers,
        };
      }
      return {
        ...state,
        onlineModels: newRooms,
        onlineMembers: newMembers,
        selectedModel: newModelHelper,
      };
    case actionConstants.FETCH_NOTIFICATIONS:
      return {
        ...state,
        notificationsLoader: true,
      };
    case actionConstants.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsLoader: false,
        notifications: action.notifications,
        moreNotificationsForward:
          action.moreNotificationsForward !== null
            ? action.moreNotificationsForward
            : state.moreNotificationsForward,
        moreNotificationsBackward:
          action.moreNotificationsBackward !== null
            ? action.moreNotificationsBackward
            : state.moreNotificationsBackward,
        error: null,
      };
    case actionConstants.FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: action.error,
        notificationsLoader: false,
      };
    case actionConstants.FETCH_MORE_NOTIFICATIONS:
      return {
        ...state,
        moreNotificationsLoader: true,
      };
    case actionConstants.FETCH_MORE_NOTIFICATIONS_SUCCESS:
      if (action.showMore === 'forward') {
        newNotifications = _.concat(action.notifications, state.notifications);
      }
      if (action.showMore === 'backward') {
        newNotifications = _.concat(state.notifications, action.notifications);
      }
      return {
        ...state,
        moreNotificationsLoader: false,
        notifications: newNotifications,
        moreNotificationsForward:
          action.moreNotificationsForward !== null
            ? action.moreNotificationsForward
            : state.moreNotificationsForward,
        moreNotificationsBackward:
          action.moreNotificationsBackward !== null
            ? action.moreNotificationsBackward
            : state.moreNotificationsBackward,
        error: null,
      };
    case actionConstants.FETCH_MORE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: action.error,
        moreNotificationsLoader: false,
      };
    default:
      return state;
  }
};

export default chatMonitoringReducer;
