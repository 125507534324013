import _ from 'lodash';
import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  selected: null,
  error: null,
  pagination: null,
  addedBlockedWord: false,
  editedBlockedWord: false,
  deletedBlockedWord: false,
};

const blockedWordsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_BLOCKED_WORDS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_BLOCKED_WORDS_SUCCESS:
      return {
        ...state,
        items: action.blockedWords,
        pagination: action.pagination,
        loader: false,
      };
    case actionConstants.FETCH_BLOCKED_WORDS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SELECT_BLOCKED_WORD:
      return {
        ...state,
        selected: action.blockedWord,
      };
    case actionConstants.ADD_BLOCKED_WORD:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.ADD_BLOCKED_WORD_SUCCESS:
      return {
        ...state,
        loader: false,
        items: [...state.items, action.blockedWord],
        addedBlockedWord: true,
      };
    case actionConstants.ADD_BLOCKED_WORD_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.EDIT_BLOCKED_WORD:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.EDIT_BLOCKED_WORD_SUCCESS:
      const changedItems = [...state.items];
      const changeIndex = changedItems.findIndex(
        p => p.id === action.blockedWord.id
      );
      changedItems[changeIndex] = { ...action.blockedWord };
      return {
        ...state,
        loader: false,
        items: changedItems,
        editedBlockedWord: true,
      };
    case actionConstants.EDIT_BLOCKED_WORD_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.DELETE_BLOCKED_WORD:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.DELETE_BLOCKED_WORD_SUCCESS:
      const newItems = _.filter(
        state.items,
        i => i.id !== action.blockedWord.id
      );
      return {
        ...state,
        loader: false,
        selected: null,
        items: newItems,
        deletedBlockedWord: true,
      };
    case actionConstants.DELETE_BLOCKED_WORD_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.UNSELECT_BLOCKED_WORD:
      return {
        ...state,
        selected: null,
        addedBlockedWord: false,
        editedBlockedWord: false,
        deletedBlockedWord: false,
        error: null,
      };
    default:
      return state;
  }
};

export default blockedWordsReducer;
