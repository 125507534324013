import React from 'react';
import { Link } from 'react-router-dom';
import Image from '@iso/assets/images/rob.png';
import IntlMessages from '@iso/components/utility/intlMessages';
import FourZeroFourStyleWrapper from './404.styles';
import appActions, { clearGlobalError } from '@iso/redux/app/actions';
import { useDispatch, useSelector } from 'react-redux';

const { changeCurrent } = appActions;

export default function() {
  const dispatch = useDispatch();
  const globalErrorMessage = useSelector(state => state.App.globalErrorMessage);

  function onBackButtonClick() {
    dispatch(changeCurrent(['dashboard']));
    dispatch(clearGlobalError());
  }

  return (
    <FourZeroFourStyleWrapper className="iso404Page">
      <div className="iso404Row">
        <div className="iso404Content">
          <h1>
            <IntlMessages id="page404.title" />
          </h1>
          <h3>
            <IntlMessages id="page404.subTitle" />
          </h3>
          <p>
            <IntlMessages id="page404.description" />
          </p>
          <Link to="/dashboard" onClick={onBackButtonClick}>
            <button type="button">
              <IntlMessages id="page404.backButton" />
            </button>
          </Link>
        </div>

        <div className="iso404Artwork">
          <img alt="#" src={Image} />
        </div>
      </div>

      {globalErrorMessage && (
        <div className="iso404Error">
          <p>
            <span className="error">Error message: </span>
            {globalErrorMessage}
          </p>
        </div>
      )}
    </FourZeroFourStyleWrapper>
  );
}
