export default {
  FETCH_TIPS: 'FETCH_TIPS',
  FETCH_TIPS_SUCCESS: 'FETCH_TIPS_SUCCESS',
  FETCH_TIPS_ERROR: 'FETCH_TIPS_ERROR',
  SELECT_TIP: 'SELECT_TIP',
  UNSELECT_TIP: 'UNSELECT_TIP',
  ADD_TIP: 'ADD_TIP',
  ADD_TIP_SUCCESS: 'ADD_TIP_SUCCESS',
  ADD_TIP_ERROR: 'ADD_TIP_ERROR',
  EDIT_TIP: 'EDIT_TIP',
  EDIT_TIP_SUCCESS: 'EDIT_TIP_SUCCESS',
  EDIT_TIP_ERROR: 'EDIT_TIP_ERROR',
  DELETE_TIP: 'DELETE_TIP',
  DELETE_TIP_SUCCESS: 'DELETE_TIP_SUCCESS',
  DELETE_TIP_ERROR: 'DELETE_TIP_ERROR',
};
