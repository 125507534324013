export default [
  'model_name',
  'modelScreenName',
  'member_name',
  'start_date',
  'end_date',
  'sort',
  'page',
  'pageSize',
  'id_model',
  'id_member',
  'transactionType',
  'start_time',
  'end_time',
];
