import actionConstants from './constants';
import _ from 'lodash';

export const INIT_STATE = {
  loader: false,
  error: null,

  studios: [],
  allCurrentVariances: [],
  items: [],
  selected: false,

  payoutVariancesByType: {
    check: [],
    loading_check: false,
    wired_transfer: [],
    loading_wire_transfer: false,
    direct_deposit: [],
    loading_direct_deposit: false,
    paxum: [],
    loading_paxum: false,
  },
};

const transactionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_VARIANCES:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_VARIANCES_SUCCESS:
      return {
        ...state,
        studios: action.studios,
        allCurrentVariances: action.variances,
        loader: false,
        selected: action.payoutMethod,
      };
    case actionConstants.FETCH_VARIANCES_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.FETCH_VARIANCES_BY_TYPE:
      return {
        ...state,
        error: null,
        payoutVariancesByType: {
          ...state.payoutVariancesByType,
          [`loading_${action.payoutMethod}`]: true,
        },
      };
    case actionConstants.FETCH_VARIANCES_BY_TYPE_SUCCESS:
      return {
        ...state,
        studios: _.uniqBy([...action.studios, ...state.studios], 'studio_id'),
        payoutVariancesByType: {
          ...state.payoutVariancesByType,
          [action.payoutMethod]: action.variances,
          [`loading_${action.payoutMethod}`]: false,
        },
      };
    case actionConstants.FETCH_VARIANCES_BY_TYPE_ERROR:
      return {
        ...state,
        error: action.error,
        payoutVariancesByType: {
          ...state.payoutVariancesByType,
          [`loading_${action.payoutMethod}`]: true,
        },
      };

    case actionConstants.FILTER_VARIANCES:
      return {
        ...state,
        loader: true,
      };
    case actionConstants.FILTER_VARIANCES_SUCCESS:
      let newItems = [];
      let newCurrentVariances = [];
      if (action.payoutMethod === 'review' || action.payoutMethod === 'all') {
        for (const [key, value] of Object.entries(
          state.payoutVariancesByType
        )) {
          if (value && value.length) {
            for (let i = 0; i < value.length; i++) {
              if (
                (action.payoutMethod === 'review' &&
                  value[i].should_be_reviewed) ||
                action.payoutMethod === 'all'
              ) {
                newCurrentVariances.push({
                  ...value[i],
                  no: newCurrentVariances.length + 1,
                });
              }
            }
          }
        }
      } else if (action.payoutMethod !== state.selected) {
        newCurrentVariances = [
          ...state.payoutVariancesByType[action.payoutMethod],
        ];
      } else {
        newCurrentVariances = [...state.allCurrentVariances];
      }

      if (action.name || action.id) {
        for (let i = 0; i < newCurrentVariances.length; i++) {
          if (
            newCurrentVariances[i].studio_name === action.name ||
            newCurrentVariances[i].imported_studio_id === +action.id
          ) {
            newItems.push(newCurrentVariances[i]);
          }
        }
      } else {
        newItems = newCurrentVariances;
      }

      newItems = newItems.filter(item => {
        let shouldBeReturned = true;
        for (let i = 0; i < action.filterValues.length; i++) {
          let approvedByFilter = true;
          if (action.filterValues[i].amount) {
            if (
              (!action.filterValues[i].sign ||
                action.filterValues[i].sign === '>') &&
              Math.abs(item[action.filterValues[i].name]) <=
                action.filterValues[i].amount
            ) {
              approvedByFilter = false;
            }
            if (
              action.filterValues[i].sign === '<' &&
              Math.abs(item[action.filterValues[i].name]) >=
                action.filterValues[i].amount
            ) {
              approvedByFilter = false;
            }
          }
          if (
            !action.filterValues[i].operator ||
            action.filterValues[i].operator === 'and'
          ) {
            shouldBeReturned = shouldBeReturned && approvedByFilter;
          }
          if (action.filterValues[i].operator === 'or') {
            shouldBeReturned = shouldBeReturned || approvedByFilter;
          }
        }
        if (shouldBeReturned) {
          return item;
        }
      });

      return {
        ...state,
        loader: false,
        items: newItems,
        allCurrentVariances: newCurrentVariances,
        selected: action.payoutMethod,
      };
    case actionConstants.FILTER_VARIANCES_ERROR:
      return {
        ...state,
        loader: false,
      };
    case actionConstants.FETCH_MODEL_VARIANCES:
      const newStudiosLoader = state.studios.map(studio => {
        if (action.id_studio === studio.studio_id) {
          studio.modelsLoader = true;
        }
        return studio;
      });
      return {
        ...state,
        studios: newStudiosLoader,
      };
    case actionConstants.FETCH_MODEL_VARIANCES_SUCCESS:
      const newStudiosSuccess = state.studios.map(studio => {
        if (action.id_studio === studio.studio_id) {
          studio.modelsVariances = action.modelsVariances;
          studio.models = action.models;
          studio.modelsLoader = false;
        }
        return studio;
      });
      return {
        ...state,
        studios: newStudiosSuccess,
      };
    case actionConstants.FETCH_MODEL_VARIANCES_ERROR:
      const newStudiosError = state.studios.map(studio => {
        if (action.id_studio === studio.studio_id) {
          studio.modelsLoader = false;
        }
        return studio;
      });
      return {
        ...state,
        studios: newStudiosError,
        error: action.error,
      };
    case actionConstants.SAVE_PAYOUT_REVIEW_SUCCESS:
      const newVariancesReview = state.items.map(studio => {
        if (studio.studio_id === action.idStudio) {
          studio.reviewed = true;
          studio.review_status = action.reviewStatus;
          studio.review = action.review;
        }
        return studio;
      });
      const newStudiosPayoutReview = state.studios.map(studio => {
        if (studio.studio_id === action.idStudio) {
          studio.first_period.reviewed = true;
          studio.first_period.review_status = action.reviewStatus;
          studio.first_period.review = action.review;
        }
        return studio;
      });
      return {
        ...state,
        items: newVariancesReview,
        studios: newStudiosPayoutReview,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
