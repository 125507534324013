export default {
  FETCH_USER_SETTINGS: 'FETCH_USER_SETTINGS',
  FETCH_USER_SETTINGS_SUCCESS: 'FETCH_USER_SETTINGS_SUCCESS',
  FETCH_USER_SETTINGS_ERROR: 'FETCH_USER_SETTINGS_ERROR',
  EDIT_USER_SETTINGS: 'EDIT_USER_SETTINGS',
  EDIT_USER_SETTINGS_SUCCESS: 'EDIT_USER_SETTINGS_SUCCESS',
  EDIT_USER_SETTINGS_ERROR: 'EDIT_USER_SETTINGS_ERROR',
  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
  CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_ERROR: 'CHANGE_USER_PASSWORD_ERROR',
  SET_PASSWORD_CHANGED_TO_FALSE: 'SET_PASSWORD_CHANGED_TO_FALSE',
};
