export default {
  FETCH_MODELS: 'FETCH_MODELS',
  FETCH_MODELS_SUCCESS: 'FETCH_MODELS_SUCCESS',
  FETCH_MODELS_ERROR: 'FETCH_MODELS_ERROR',
  SELECT_MODEL: 'SELECT_MODEL',
  UNSELECT_MODEL: 'UNSELECT_MODEL',
  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_ERROR: 'FETCH_COUNTRIES_ERROR',
  ADD_MODEL_TIERS: 'ADD_MODEL_TIERS',
  ADD_MODEL_TIERS_SUCCESS: 'ADD_MODEL_TIERS_SUCCESS',
  ADD_MODEL_TIERS_ERROR: 'ADD_MODEL_TIERS_ERROR',
  FETCH_MODEL_TIERS: 'FETCH_MODEL_TIERS',
  FETCH_MODEL_TIERS_SUCCESS: 'FETCH_MODEL_TIERS_SUCCESS',
  FETCH_MODEL_TIERS_ERROR: 'FETCH_MODEL_TIERS_ERROR',
  REMOVE_MODEL_TIERS: 'REMOVE_MODEL_TIERS',
  REMOVE_MODEL_TIERS_SUCCESS: 'REMOVE_MODEL_TIERS_SUCCESS',
  REMOVE_MODEL_TIERS_ERROR: 'REMOVE_MODEL_TIERS_ERROR',
  ADD_MODEL_FLAT_PERCENTAGE: 'ADD_MODEL_FLAT_PERCENTAGE',
  ADD_MODEL_FLAT_PERCENTAGE_SUCCESS: 'ADD_MODEL_FLAT_PERCENTAGE_SUCCESS',
  ADD_MODEL_FLAT_PERCENTAGE_ERROR: 'ADD_MODEL_FLAT_PERCENTAGE_ERROR',
  FETCH_MODEL_FLAT_PERCENTAGE: 'FETCH_MODEL_FLAT_PERCENTAGE',
  FETCH_MODEL_FLAT_PERCENTAGE_SUCCESS: 'FETCH_MODEL_FLAT_PERCENTAGE_SUCCESS',
  FETCH_MODEL_FLAT_PERCENTAGE_ERROR: 'FETCH_MODEL_FLAT_PERCENTAGE_ERROR',
  REMOVE_MODEL_FLAT_PERCENTAGE: 'REMOVE_MODEL_FLAT_PERCENTAGE',
  REMOVE_MODEL_FLAT_PERCENTAGE_SUCCESS: 'REMOVE_MODEL_FLAT_PERCENTAGE_SUCCESS',
  REMOVE_MODEL_FLAT_PERCENTAGE_ERROR: 'REMOVE_MODEL_FLAT_PERCENTAGE_ERROR',
  FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE:
    'FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE',
  FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE_SUCCESS:
    'FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE_SUCCESS',
  FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE_ERROR:
    'FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE_ERROR',
  FETCH_MODEL_COUNT: 'FETCH_MODEL_COUNT',
  FETCH_MODEL_COUNT_SUCCESS: 'FETCH_MODEL_COUNT_SUCCESS',
  FETCH_MODEL_COUNT_ERROR: 'FETCH_MODEL_COUNT_ERROR',
};
