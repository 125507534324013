export default {
  FETCH_BLOCKED_WORDS: 'FETCH_BLOCKED_WORDS',
  FETCH_BLOCKED_WORDS_SUCCESS: 'FETCH_BLOCKED_WORDS_SUCCESS',
  FETCH_BLOCKED_WORDS_ERROR: 'FETCH_BLOCKED_WORDS_ERROR',
  SELECT_BLOCKED_WORD: 'SELECT_BLOCKED_WORD',
  UNSELECT_BLOCKED_WORD: 'UNSELECT_BLOCKED_WORD',
  ADD_BLOCKED_WORD: 'ADD_BLOCKED_WORD',
  ADD_BLOCKED_WORD_SUCCESS: 'ADD_BLOCKED_WORD_SUCCESS',
  ADD_BLOCKED_WORD_ERROR: 'ADD_BLOCKED_WORD_ERROR',
  EDIT_BLOCKED_WORD: 'EDIT_BLOCKED_WORD',
  EDIT_BLOCKED_WORD_SUCCESS: 'EDIT_BLOCKED_WORD_SUCCESS',
  EDIT_BLOCKED_WORD_ERROR: 'EDIT_BLOCKED_WORD_ERROR',
  DELETE_BLOCKED_WORD: 'DELETE_BLOCKED_WORD',
  DELETE_BLOCKED_WORD_SUCCESS: 'DELETE_BLOCKED_WORD_SUCCESS',
  DELETE_BLOCKED_WORD_ERROR: 'DELETE_BLOCKED_WORD_ERROR',
};
