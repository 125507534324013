import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  selected: null,
  error: null,
  selectedStudioModels: [],
  selectedStudioInfos: [],
  selectedStudioPayouts: [],
  studiosPayouts: [],
  studiosPayoutsByTipAndPeriod: [],
  modelLoader: false,
  payoutsLoader: false,
  pagination: null,
  paginationPayouts: null,
  paginationEarnings: null,
  count: null,

  modelEarningsLoader: false,
  modelEarnings: [],

  selectedStudioSumsLoader: false,
  selectedStudioSums: {},
};

const studiosReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_STUDIOS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_STUDIOS_SUCCESS:
      return {
        ...state,
        items: action.studios,
        loader: false,
        error: null,
        pagination: action.pagination,
      };
    case actionConstants.FETCH_STUDIOS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.FETCH_STUDIOS_PAYOUTS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_STUDIOS_PAYOUTS_SUCCESS:
      return {
        ...state,
        studiosPayouts: action.studiosPayouts,
        loader: false,
        error: null,
      };
    case actionConstants.FETCH_STUDIOS_PAYOUTS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.FETCH_STUDIOS_BY_TIP_AND_PERIOD:
      return {
        ...state,
        loader: true,
        studiosPayoutsByTipAndPeriod: [],
        error: null,
      };
    case actionConstants.FETCH_STUDIOS_BY_TIP_AND_PERIOD_SUCCESS:
      return {
        ...state,
        studiosPayoutsByTipAndPeriod: action.studiosPayoutsByTipAndPeriod,
        loader: false,
        error: null,
      };
    case actionConstants.FETCH_STUDIOS_BY_TIP_AND_PERIOD_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SELECT_STUDIO:
      return {
        ...state,
        selected: action.studio,
        loader: false,
        error: null,
      };
    case actionConstants.UNSELECT_STUDIO:
      return {
        ...state,
        selected: null,
        loader: false,
        error: null,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_MODELS:
      return {
        ...state,
        modelLoader: true,
        error: null,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_MODELS_SUCCESS:
      return {
        ...state,
        selectedStudioModels: action.models,
        modelLoader: false,
        error: null,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_MODELS_ERROR:
      return {
        ...state,
        modelLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_PAYOUTS:
      return {
        ...state,
        payoutsLoader: true,
        error: null,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_PAYOUTS_SUCCESS:
      return {
        ...state,
        selectedStudioPayouts: action.payouts,
        paginationPayouts: action.pagination,
        payoutsLoader: false,
        error: null,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_PAYOUTS_ERROR:
      return {
        ...state,
        payoutsLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_MODELS_EARNINGS:
      return {
        ...state,
        modelLoader: true,
        error: null,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_MODELS_EARNINGS_SUCCESS:
      return {
        ...state,
        selectedStudioInfos: action.infos,
        paginationEarnings: action.pagination,
        modelLoader: false,
        error: null,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_MODELS_EARNINGS_ERROR:
      return {
        ...state,
        modelLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_STUDIO_COUNT:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_STUDIO_COUNT_SUCCESS:
      return {
        ...state,
        loader: false,
        count: action.count,
        error: null,
      };
    case actionConstants.FETCH_STUDIO_COUNT_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODEL_EARNINGS:
      return {
        ...state,
        modelEarningsLoader: true,
        modelEarnings: [],
        error: null,
      };
    case actionConstants.FETCH_MODEL_EARNINGS_SUCCESS:
      return {
        ...state,
        modelEarnings: action.modelEarnings,
        modelEarningsLoader: false,
      };
    case actionConstants.FETCH_MODEL_EARNINGS_ERROR:
      return {
        ...state,
        modelEarningsLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_SELECTED_STUDIO_TOTAL_EARNINGS:
      return {
        ...state,
        selectedStudioSumsLoader: true,
        selectedStudioSums: {},
      };
    case actionConstants.FETCH_SELECTED_STUDIO_TOTAL_EARNINGS_SUCCESS: {
      return {
        ...state,
        selectedStudioSumsLoader: false,
        selectedStudioSums: {
          ...action.totalStudioSums,
          total:
            action.totalStudioSums.tieredPayoutTotal +
            action.totalStudioSums.bonusPayoutTotal -
            action.totalStudioSums.payoutAdjustment,
        },
      };
    }
    case actionConstants.FETCH_SELECTED_STUDIO_TOTAL_EARNINGS_ERROR: {
      return {
        ...state,
        selectedStudioSumsLoader: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default studiosReducer;
