import actionConstants from './constants';

export const INIT_STATE = {
  isLoggedIn: false,
  username: null,
  loader: false,
  error: null,
  loginError: null,
  id: null,
  first_name: null,
  last_name: null,
  theme: null,
  features: [],
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.LOGIN_REQUEST:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.LOGIN_SUCCESS:
      localStorage.setItem('accessToken', action.accessToken);
      localStorage.setItem('refreshToken', action.refreshToken);
      localStorage.setItem('username', action.username);
      localStorage.setItem('id', action.id);
      localStorage.setItem('first_name', action.first_name);
      localStorage.setItem('last_name', action.last_name);
      localStorage.setItem('theme', action.theme);
      return {
        ...state,
        isLoggedIn: true,
        username: action.username,
        id: action.id,
        first_name: action.first_name,
        last_name: action.last_name,
        theme: action.theme,
        features: action.features,
        error: null,
        loginError: null,
        loader: false,
      };
    case actionConstants.LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
        loader: false,
      };
    case actionConstants.LOGOUT:
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('username');
      localStorage.removeItem('id');
      localStorage.removeItem('first_name');
      localStorage.removeItem('last_name');
      localStorage.removeItem('theme');
      return INIT_STATE;
    default:
      return state;
  }
};

export default authReducer;
