import actionConstants from './constants';
import _ from 'lodash';

export const INIT_STATE = {
  items: [],
  loader: false,
  selected: null,
  error: null,
  addedUser: false,
  changedPasswordUser: false,
  editedUser: false,
  deletedUser: false,
};

const usersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_USERS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_USERS_SUCCESS:
      return {
        ...state,
        items: action.users,
        loader: false,
      };
    case actionConstants.FETCH_USERS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SELECT_USER:
      return {
        ...state,
        selected: action.user,
      };
    case actionConstants.ADD_USER:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        loader: false,
        items: [...state.items, action.user],
        addedUser: true,
      };
    case actionConstants.ADD_USER_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.EDIT_USER:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.EDIT_USER_SUCCESS:
      const changedItems = [...state.items];
      const changeIndex = changedItems.findIndex(p => p.id === action.user.id);
      changedItems[changeIndex] = action.user;
      return {
        ...state,
        loader: false,
        items: changedItems,
        editedUser: true,
      };
    case actionConstants.EDIT_USER_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.DELETE_USER:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.DELETE_USER_SUCCESS:
      const newItems = _.filter(state.items, i => i.id !== action.user.id);
      return {
        ...state,
        loader: false,
        selected: null,
        items: newItems,
        deletedUser: true,
      };
    case actionConstants.DELETE_USER_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.CHANGE_USER_PASSWORD:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loader: false,
        selected: null,
        changedPasswordUser: true,
      };
    case actionConstants.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.UNSELECT_USER:
      return {
        ...state,
        selected: null,
        error: null,
        addedUser: false,
        editedUser: false,
        deletedUser: false,
        changedPasswordUser: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
