export default {
  FETCH_MEMBER_CREDIT_TRANSACTIONS: 'FETCH_MEMBER_CREDIT_TRANSACTIONS',
  FETCH_MEMBER_CREDIT_TRANSACTIONS_SUCCESS:
    'FETCH_MEMBER_CREDIT_TRANSACTIONS_SUCCESS',
  FETCH_MEMBER_CREDIT_TRANSACTIONS_ERROR:
    'FETCH_MEMBER_CREDIT_TRANSACTIONS_ERROR',
  FETCH_MEMBER_DEBIT_TRANSACTIONS: 'FETCH_MEMBER_DEBIT_TRANSACTIONS',
  FETCH_MEMBER_DEBIT_TRANSACTIONS_SUCCESS:
    'FETCH_MEMBER_DEBIT_TRANSACTIONS_SUCCESS',
  FETCH_MEMBER_DEBIT_TRANSACTIONS_ERROR:
    'FETCH_MEMBER_DEBIT_TRANSACTIONS_ERROR',
  SELECT_TRANSACTION: 'SELECT_TRANSACTION',
  UNSELECT_TRANSACTION: 'UNSELECT_TRANSACTION',
  ADD_REFUND: 'ADD_REFUND',
  ADD_REFUND_SUCCESS: 'ADD_REFUND_SUCCESS',
  ADD_REFUND_ERROR: 'ADD_REFUND_ERROR',
  FETCH_TOTAL_TOKEN_SPENT: 'FETCH_TOTAL_TOKEN_SPENT',
  FETCH_TOTAL_TOKEN_SPENT_SUCCESS: 'FETCH_TOTAL_TOKEN_SPENT_SUCCESS',
  FETCH_TOTAL_TOKEN_SPENT_ERROR: 'FETCH_TOTAL_TOKEN_SPENT_ERROR',
  FETCH_TOTAL_TOKEN_BOUGHT: 'FETCH_TOTAL_TOKEN_BOUGHT',
  FETCH_TOTAL_TOKEN_BOUGHT_SUCCESS: 'FETCH_TOTAL_TOKEN_BOUGHT_SUCCESS',
  FETCH_TOTAL_TOKEN_BOUGHT_ERROR: 'FETCH_TOTAL_TOKEN_BOUGHT_ERROR',
};
