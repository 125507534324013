import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  tiersLoader: false,
  percentageLoader: false,
  selected: null,
  error: null,
  earnings: [],
  pagination: null,
  countries: [],
  tiers: [],
  flatPercentage: null,
  loaderCountries: false,
  tiersChanged: false,
  percentageChanged: false,
  count: null,
};

const modelsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_MODELS:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_MODELS_SUCCESS:
      return {
        ...state,
        items: action.models,
        pagination: action.pagination,
        loader: false,
        error: null,
      };
    case actionConstants.FETCH_MODELS_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SELECT_MODEL:
      return {
        ...state,
        selected: action.model,
        loader: false,
        error: null,
        tiersChanged: false,
        percentageChanged: false,
      };
    case actionConstants.UNSELECT_MODEL:
      return {
        ...state,
        selected: null,
        tiers: [],
        loader: false,
        error: null,
        tiersChanged: false,
        percentageChanged: false,
      };
    case actionConstants.FETCH_COUNTRIES:
      return {
        ...state,
        loaderCountries: true,
        error: null,
      };
    case actionConstants.FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        loaderCountries: false,
        error: null,
        countries: action.countries,
      };
    }
    case actionConstants.FETCH_COUNTRIES_ERROR: {
      return {
        ...state,
        loaderCountries: false,
        error: action.error,
      };
    }
    case actionConstants.ADD_MODEL_TIERS:
      return {
        ...state,
        tiersLoader: true,
        error: null,
        tiersChanged: false,
      };
    case actionConstants.ADD_MODEL_TIERS_SUCCESS:
      return {
        ...state,
        tiersLoader: false,
        tiers: action.tiers,
        error: null,
        tiersChanged: true,
      };
    case actionConstants.ADD_MODEL_TIERS_ERROR:
      return {
        ...state,
        tiersLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODEL_TIERS:
      return {
        ...state,
        tiersLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_TIERS_SUCCESS:
      return {
        ...state,
        tiersLoader: false,
        tiers: action.tiers,
        error: null,
      };
    case actionConstants.FETCH_MODEL_TIERS_ERROR:
      return {
        ...state,
        tiersLoader: false,
        error: action.error,
      };
    case actionConstants.REMOVE_MODEL_TIERS:
      return {
        ...state,
        tiersLoader: true,
        error: null,
        tiersChanged: false,
      };
    case actionConstants.REMOVE_MODEL_TIERS_SUCCESS:
      return {
        ...state,
        tiersLoader: false,
        tiers: [],
        error: null,
        tiersChanged: true,
      };
    case actionConstants.REMOVE_MODEL_TIERS_ERROR:
      return {
        ...state,
        tiersLoader: false,
        error: action.error,
      };
    case actionConstants.ADD_MODEL_FLAT_PERCENTAGE:
      return {
        ...state,
        percentageLoader: true,
        error: null,
        percentageChanged: false,
      };
    case actionConstants.ADD_MODEL_FLAT_PERCENTAGE_SUCCESS:
      return {
        ...state,
        percentageLoader: false,
        flatPercentage: action.percentage,
        error: null,
        percentageChanged: true,
      };
    case actionConstants.ADD_MODEL_FLAT_PERCENTAGE_ERROR:
      return {
        ...state,
        percentageLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODEL_FLAT_PERCENTAGE:
      return {
        ...state,
        percentageLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_FLAT_PERCENTAGE_SUCCESS:
      return {
        ...state,
        percentageLoader: false,
        flatPercentage: action.percentage,
        error: null,
      };
    case actionConstants.FETCH_MODEL_FLAT_PERCENTAGE_ERROR:
      return {
        ...state,
        percentageLoader: false,
        error: action.error,
      };
    case actionConstants.REMOVE_MODEL_FLAT_PERCENTAGE:
      return {
        ...state,
        percentageLoader: true,
        error: null,
        percentageChanged: false,
      };
    case actionConstants.REMOVE_MODEL_FLAT_PERCENTAGE_SUCCESS:
      return {
        ...state,
        percentageLoader: false,
        flatPercentage: null,
        error: null,
        percentageChanged: true,
      };
    case actionConstants.REMOVE_MODEL_FLAT_PERCENTAGE_ERROR:
      return {
        ...state,
        percentageLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE:
      return {
        ...state,
        percentageLoader: true,
        tiersLoader: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE_SUCCESS:
      return {
        ...state,
        percentageLoader: false,
        tiersLoader: false,
        flatPercentage: action.percentage,
        tiers: action.tiers,
        error: null,
      };
    case actionConstants.FETCH_MODEL_CUSTOM_TIERS_AND_FLAT_PERCENTAGE_ERROR:
      return {
        ...state,
        percentageLoader: false,
        tiersLoader: false,
        error: action.error,
      };
    case actionConstants.FETCH_MODEL_COUNT:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_MODEL_COUNT_SUCCESS:
      return {
        ...state,
        loader: false,
        count: action.count,
        error: null,
      };
    case actionConstants.FETCH_MODEL_COUNT_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default modelsReducer;
