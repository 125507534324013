import _ from 'lodash';
import actionConstants from './constants';

export const INIT_STATE = {
  items: [],
  loader: false,
  selected: null,
  error: null,
  addedTokenPackage: false,
  editedTokenPackage: false,
  deletedTokenPackage: false,
};

const tokenPackagesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_TOKEN_PACKAGES:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_TOKEN_PACKAGES_SUCCESS:
      return {
        ...state,
        items: action.tokenPackages,
        loader: false,
      };
    case actionConstants.FETCH_TOKEN_PACKAGES_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    case actionConstants.SELECT_TOKEN_PACKAGE:
      return {
        ...state,
        selected: action.tokenPackage,
      };
    case actionConstants.ADD_TOKEN_PACKAGE:
      return {
        ...state,
        loader: true,
        error: null,
        addedTokenPackage: false,
      };
    case actionConstants.ADD_TOKEN_PACKAGE_SUCCESS:
      return {
        ...state,
        loader: false,
        items: [...state.items, action.tokenPackage],
        addedTokenPackage: true,
      };
    case actionConstants.ADD_TOKEN_PACKAGE_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
        addedTokenPackage: false,
      };
    case actionConstants.EDIT_TOKEN_PACKAGE:
      return {
        ...state,
        loader: true,
        error: null,
        editedTokenPackage: false,
      };
    case actionConstants.EDIT_TOKEN_PACKAGE_SUCCESS:
      const changedItems = [...state.items];
      const changeIndex = changedItems.findIndex(
        p => p.id === action.tokenPackage.id
      );
      changedItems[changeIndex] = action.tokenPackage;
      return {
        ...state,
        loader: false,
        items: changedItems,
        editedTokenPackage: true,
      };
    case actionConstants.EDIT_TOKEN_PACKAGE_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
        editedTokenPackage: false,
      };
    case actionConstants.DELETE_TOKEN_PACKAGE:
      return {
        ...state,
        loader: true,
        error: null,
        deletedTokenPackage: false,
      };
    case actionConstants.DELETE_TOKEN_PACKAGE_SUCCESS:
      const newItems = _.filter(
        state.items,
        i => i.id !== action.tokenPackage.id
      );
      return {
        ...state,
        loader: false,
        selected: null,
        items: newItems,
        deletedTokenPackage: true,
      };
    case actionConstants.DELETE_TOKEN_PACKAGE_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
        deletedTokenPackage: false,
      };
    case actionConstants.UNSELECT_TOKEN_PACKAGE:
      return {
        ...state,
        selected: null,
        error: null,
        addedTokenPackage: false,
        editedTokenPackage: false,
        deletedTokenPackage: false,
      };
    default:
      return state;
  }
};

export default tokenPackagesReducer;
