export default {
  FETCH_STUDIOS: 'FETCH_STUDIOS',
  FETCH_STUDIOS_SUCCESS: 'FETCH_STUDIOS_SUCCESS',
  FETCH_STUDIOS_ERROR: 'FETCH_STUDIOS_ERROR',
  FETCH_STUDIOS_PAYOUTS: 'FETCH_STUDIOS_PAYOUTS',
  FETCH_STUDIOS_PAYOUTS_SUCCESS: 'FETCH_STUDIOS_PAYOUTS_SUCCESS',
  FETCH_STUDIOS_PAYOUTS_ERROR: 'FETCH_STUDIOS_PAYOUTS_ERROR',
  FETCH_STUDIOS_BY_TIP_AND_PERIOD: 'FETCH_STUDIOS_BY_TIP_AND_PERIOD',
  FETCH_STUDIOS_BY_TIP_AND_PERIOD_SUCCESS:
    'FETCH_STUDIOS_BY_TIP_AND_PERIOD_SUCCESS',
  FETCH_STUDIOS_BY_TIP_AND_PERIOD_ERROR:
    'FETCH_STUDIOS_BY_TIP_AND_PERIOD_ERROR',

  FETCH_MODEL_EARNINGS: 'FETCH_MODEL_EARNINGS',
  FETCH_MODEL_EARNINGS_SUCCESS: 'FETCH_MODEL_EARNINGS_SUCCESS',
  FETCH_MODEL_EARNINGS_ERROR: 'FETCH_MODEL_EARNINGS_ERROR',

  SELECT_STUDIO: 'SELECT_STUDIO',
  UNSELECT_STUDIO: 'UNSELECT_STUDIO',
  FETCH_SELECTED_STUDIO_MODELS: 'FETCH_SELECTED_STUDIO_MODELS',
  FETCH_SELECTED_STUDIO_MODELS_SUCCESS: 'FETCH_SELECTED_STUDIO_MODELS_SUCCESS',
  FETCH_SELECTED_STUDIO_MODELS_ERROR: 'FETCH_SELECTED_STUDIO_MODELS_ERROR',
  FETCH_SELECTED_STUDIO_PAYOUTS: 'FETCH_SELECTED_STUDIO_PAYOUTS',
  FETCH_SELECTED_STUDIO_PAYOUTS_SUCCESS:
    'FETCH_SELECTED_STUDIO_PAYOUTS_SUCCESS',
  FETCH_SELECTED_STUDIO_PAYOUTS_ERROR: 'FETCH_SELECTED_STUDIO_PAYOUTSS_ERROR',
  FETCH_SELECTED_STUDIO_MODELS_EARNINGS:
    'FETCH_SELECTED_STUDIO_MODELS_EARNINGS',
  FETCH_SELECTED_STUDIO_MODELS_EARNINGS_SUCCESS:
    'FETCH_SELECTED_STUDIO_MODELS_EARNINGS_SUCCESS',
  FETCH_SELECTED_STUDIO_MODELS_EARNINGS_ERROR:
    'FETCH_SELECTED_STUDIO_MODELS_EARNINGS_ERROR',
  FETCH_STUDIO_COUNT: 'FETCH_STUDIO_COUNT',
  FETCH_STUDIO_COUNT_SUCCESS: 'FETCH_STUDIO_COUNT_SUCCESS',
  FETCH_STUDIO_COUNT_ERROR: 'FETCH_STUDIO_COUNT_ERROR',

  FETCH_SELECTED_STUDIO_TOTAL_EARNINGS: 'FETCH_SELECTED_STUDIO_TOTAL_EARNINGS',
  FETCH_SELECTED_STUDIO_TOTAL_EARNINGS_SUCCESS:
    'FETCH_SELECTED_STUDIO_TOTAL_EARNINGS_SUCCESS',
  FETCH_SELECTED_STUDIO_TOTAL_EARNINGS_ERROR:
    'FETCH_SELECTED_STUDIO_TOTAL_EARNINGS_ERROR',
};
