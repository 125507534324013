import actionConstants from './constants';

export const INIT_STATE = {
  data: {},
  loader: false,
  error: null,
};

const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionConstants.FETCH_INITIAL_DATA:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case actionConstants.FETCH_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loader: false,
      };
    case actionConstants.FETCH_INITIAL_DATA_ERROR:
      return {
        ...state,
        loader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
