export default {
  FETCH_VARIANCES: 'FETCH_VARIANCES',
  FETCH_VARIANCES_SUCCESS: 'FETCH_VARIANCES_SUCCESS',
  FETCH_VARIANCES_ERROR: 'FETCH_VARIANCES_ERROR',

  FETCH_VARIANCES_BY_TYPE: 'FETCH_VARIANCES_BY_TYPE',
  FETCH_VARIANCES_BY_TYPE_SUCCESS: 'FETCH_VARIANCES_BY_TYPE_SUCCESS',
  FETCH_VARIANCES_BY_TYPE_ERROR: 'FETCH_VARIANCES_BY_TYPE_ERROR',

  FILTER_VARIANCES: 'FILTER_VARIANCES',
  FILTER_VARIANCES_SUCCESS: 'FILTER_VARIANCES_SUCCESS',
  FILTER_VARIANCES_ERROR: 'FILTER_VARIANCES_ERROR',

  FETCH_MODEL_VARIANCES: 'FETCH_MODEL_VARIANCES',
  FETCH_MODEL_VARIANCES_SUCCESS: 'FETCH_MODEL_VARIANCES_SUCCESS',
  FETCH_MODEL_VARIANCES_ERROR: 'FETCH_MODEL_VARIANCES_ERROR',

  SAVE_PAYOUT_REVIEW: 'SAVE_PAYOUT_REVIEW',
  SAVE_PAYOUT_REVIEW_SUCCESS: 'SAVE_PAYOUT_REVIEW_SUCCESS',
  SAVE_PAYOUT_REVIEW_ERROR: 'SAVE_PAYOUT_REVIEW_ERROR',
};
